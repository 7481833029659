'use client';

import {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
} from 'react';
import { use } from 'react';


const UserContext = createContext(null);

export function useUser() {
    let context = useContext(UserContext);
    if (context === null) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
}

export function UserProvider({
    children,
    userPromise,
}) {
    let initialUser = use(userPromise);
    let [user, setUser] = useState(initialUser);

    useEffect(() => {
        setUser(initialUser);
    }, [initialUser]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            {children}
        </UserContext.Provider>
    );
}