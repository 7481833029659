// theme-provider.tsx
'use client';

import * as React from 'react';
import dynamic from 'next/dynamic';

const NextThemesProvider = dynamic(
  () => import('next-themes').then((mod) => mod.ThemeProvider),
  { ssr: false } // Disable SSR for ThemeProvider
);

export function ThemeProvider({ children, ...props }) {
  return <NextThemesProvider {...props}>{children}</NextThemesProvider>;
}
